.booking-detail-modal .modal-header {
    border-bottom: 1px solid #EEE7E4 !important;
}
.booking-detail-modal .modal-body {
    .payment-board {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
    .journey-vehicle {
    .pickup-wrapper {
        padding-left: 0px;
    }
    .header-wrap { 
        border-right: none;
        padding:0px;
    }
}
}
.btn {
    width: 100%;
    margin-bottom: 20px;
    .ok {
        border-radius: 12px;
        background: #F4730E;
        border: none;
        color: #ffff;
        width: 120px;
        height:40px;
    }
}
}
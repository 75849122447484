.header {
  min-height: 84px;
  font-family: "Lato";
  font-style: normal;
  .container {
  @media only screen and (max-width: 991px) {
    max-width: 900px;
  }
  @media screen and (max-width: 1400px) and (min-width: 991px) {
    min-width: 100%;
    min-height: 84px;
    overflow-x: scroll;
    border-bottom: 2px solid #ece3e0;
    }
  }
  @media only screen and (min-width: 1400px) {
  border-bottom: 2px solid #ece3e0;
  }
  @media only screen and (max-width: 991px) {
    border-bottom: 2px solid #ece3e0;
  }
  nav {
    padding: 0px 0px;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
  }
  .navbar-container {
    @media only screen and (min-width: 1660px) {
      max-width: 1540px;
    }
    // @media only screen and (max-width: 475px) {
    //   // display: grid !important;
    //   grid-template-areas:
    //     "logo logo"
    //     "tab button";
    // }
  }

  a {
    color: #f4730e;
    position: relative;
  }
  .align-items-center {
    .nav-link {
      @media only screen and (max-width: 1200px) {
        font-size: 13px;
        margin-right: 18px;
      }
      padding-right: 0px !important;
      padding-left: 0px !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      color: #000000;
      opacity: 0.6;
      margin-right: 28px;
      padding: 0;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: #000;
        font-weight: 700;
        opacity: 1;
        border-bottom: 2px solid #F4730E;
      }
    }
  }
  i {
    padding: 15px 8px;
  }

  .svg-container {
    position: relative;
  }
  .navbar-expand .navbar-nav {
    flex-direction: row;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
    a.logo {
      @media only screen and (max-width: 600px) {
        margin-top: 50px;
        margin-bottom: 50px;
      }
      @media only screen and (max-width: 600px) {
        margin-right: 0px;
      }
    }
  }
  .navbar-nav:first-child {
    .nav-link {
      @media only screen and (max-width: 991px) {
        width: 100%;
        padding: 7px;
      }
      @media only screen and (max-width: 1200px) {
        font-size: 13px;
        margin-right: 18px;
      }
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      opacity: 0.6;
      margin-right: 30px;
    }

    .nav-link.active {
      position: relative;
      font-weight: 700;
      opacity: 1;
    }    
  }

  .navbar-nav:last-child {
    .nav-link.get-started {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    .nav-link.login {
      width: 180px;
      background: #f4730e;
      border-radius: 12px;
      font-weight: 700;
      font-size: 16px;
      line-height: 35px;
      color: #ffffff;
      margin-left: 40px;

      @media screen and (max-width: 768px) {
        margin-left: 0px;
        width: 90px;
      }
    }
  }
  .rside.icon-group {
    .nav-item {
      margin-right: 30px;
      display: inline-block;
      @media screen and (max-width: 991px) {
        display:block;
      }
      &:last-child {
        margin-right: 0px;
        h6 {
          margin-left: 16px;
          display:inline;
        }
      }
    }
  }
}
.dashboard .content .indicator {
  position: absolute;
  top: 1px;
  left: 6.5px;
  z-index: 2;
  width: 6px;
  height: 6px;
  color: #fff;
  background-color: #673622;
  background-clip: padding-box;
  border: 2px solid var(--color-header-bg);
  border-radius: 50%;
}
.dashboard .content-panel__heading .svg-container a {
  position: relative;
  color: #f4730e;
}
.loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
.profile {
  display: none;
  position: absolute;
  top: 65px;
  // right: 0px;
  z-index: 3;
  background-color: white;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  background-color: rgb(250, 250, 250);
  &.active {
    display: block;
  }
  h3 {
    font-weight: 500;
    text-align: left;
    min-width: 255px;
    margin: 0px;
    padding: 18px 30px;
    font-size: 16px;
    line-height: 19px;

    &:hover {
      border-left: 3px solid #f4730e;
      background-color: rgb(235, 235, 235);
    }
  }
}
.profile-modal {
  .modal-left__thumb {
    display: flex !important;
    justify-content: center;
    img.user-image {
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }
  }
  .img-wrapper {
    display: flex;
    width: 140px;
    height: 140px;
    justify-content: center;
    img.profile-icon {
      width: 100%;
      height: 100%;
    }
  }
  .btns {
    justify-content: flex-start !important;
    .edit {
      padding: 0px 80px;
    }
    .update {
      width: 300px !important;
    }
    .modal-body .btns .edit {
      margin-left: 80px;
    }
  }
  .i-wrapper {
    i {
      color: white;
    }
  }
}

.profile-icon {
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
}
.flag-btn {
  border: unset !important;
}

a.logo img {
  height: 50px;
}
.userName {
  display: inline;
}
.notifications {
  span {
    display: none;
  @media screen and (max-width: 991px) {
    display: block;
    color: #000;
    opacity: 0.4;
  }
}
 img {
  display: inline-block;
  @media screen and (max-width: 991px) {
    display: none;
  }
 }
}

.profile-dropdown {
  display: block;
  @media screen and (max-width: 991px) {
    display: none !important;
  }
}
.language-dropdown {
  display: block;
  @media screen and (max-width: 991px) {
    display: none !important;
  } 
}
.language-dropdown + div {
  display: none !important;
  @media screen and (max-width: 991px) {
    display: block !important;
  }
}
.nav-item.profile-dropdown + div {
  display: none !important;
  @media screen and (max-width: 991px) {
    display: block !important;
  }
  h3 {
    font-size: 16px;
  }
}
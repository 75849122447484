.payment {
    .payment-board {
        .passenger-control-btn {
            margin: 0;
            width: 100%;
            padding-bottom: 30px;
            .passenger-control {
                .header-text {
                    margin: 0;
                    padding: 30px 0px;
                    margin-bottom: 20px;
                }
                .custom-row {
                    margin-bottom: 14px;
                    .input-wrapper {
                        display: flex;
                        padding: 20px 24px;
                        h5 {
                            padding: 0;
                        }
                        p {
                            margin: 0;
                            margin-left: 14px;
                            color: rgba(0,0,0,0.6);
                        }
                        &.w-100 {
                            width: 100%;
                            margin-right: 0px;
                        }
                    }
                }
                .vehicle-type, .css-1s2u09g-control {
                    background-color: rgba(242,242,242,0.4);
                    margin-bottom: 0;
                }
            }
        }
        .journey-vehicle {
            width: 100%;
            margin: 0;
            .journey {
                .journey-information {
                    margin-left: 0px;
                    .pickup-wrapper {
                        .pickup,.dropoff {
                            background-color: rgba(242,242,242,0.4);
                        }
                    }
                }
                .passenger-board {
                    .passenger, .childrens, .bags {
                        width: 80px;
                        margin-top: 0px;
            
                        .label {
                            margin-top: 0px;
                            font-size: 16px;
                        }
                        .count {
                            padding: 20px;
                            background-color: rgba(242,242,242,0.4);
                        }
                    }
                }
                .vehicle {
                    .name {
                        background-color: rgba(242,242,242,0.4);
                    }
                }
                .dash-line {
                    margin-left: -15px;
                    margin-right: -15px;
                }
                .estimated-fair {
                    padding-top: 20px;
                    .label, .cost {
                        font-size: 24px;
                    }
                }
            }
        }
    }
    .payment-board div.input-wrapper.instruction h5 {
        padding: 0;
    }
    .btns {
        .back {
            margin-bottom: 30px;
            a {
                font-size: 18px;
                font-weight: 700;
                margin-left: 35px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .payment {
            span {
                font-weight: 700;
                font-size: 18px;
                line-height: 50px;
            }
        }
        .terms {
            margin-top: 40px;
            p {
                font-size: 16px;
                line-height: 19px;
                color: rgba(0,0,0,0.4);
            }
        }
    }

}
table.driver{
    h6.onduty{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 0.9633vw;
        line-height: 24px;
        /* identical to box height, or 150% */
        text-transform: capitalize;
        color: #00CD6B;
    }
    h6.off.duty{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 0.9633vw;
        line-height: 24px;
        /* identical to box height, or 150% */
        text-transform: capitalize;
        color: #FF3131;
    }
}
div.card.driver-info{
    .meta{
        display: flex;
        .info{
            display: block;
            margin-left: 34.5px;
            .name{
                font-size: 0.93vw;
                font-weight: 700;
            }
            .date, .time{
                font-size: 0.9633vw;
                color: rgba(0,0,0,0.6);
            }
            i{
                margin-right: 10px;
            }
            >*{
                display: flex;
                margin-bottom: 20px;
            }
        }

    }
    h6.update{
        margin-top: 62px;
        width: unset;
    }
    .info{
        .wrapper{
            display: inline-table;
            margin-top: 30px;
            > *{
                display: table-cell;
            }
            div{
                width: 120px;
                margin-right: 15px;
            }
            
            h5{
                font-weight: 700;
                font-size: 0.9633vw;
                line-height: 19px;

                color: #000000;
            }
            h6{
                font-size: 0.9633vw;
                line-height: 19px;
                color: #06152B;
            }
        }
    }
}
h6.update.Assign{
    background: white;
    border:1px solid;
    border-radius: 12px;
    border-color: #F4730E;
    color: #F4730E;
    cursor: pointer;
    margin-top: 30px;
}
h6.delete{
    cursor: pointer;
}
.driver.content{
    .search{
        border: 2px solid #D9E1E7;
        border-radius: 16px;
        display: flex;
        position: relative;
        width: 298px;
        input{
            border:0;   
            padding:16.5px 27px;
            width: 80%;   
            font-style: normal;
            font-weight: 400;
            font-size: 0.9633vw;
            line-height: 19px;
            color: #06152B                          
        }
        i{
            margin-top: 12px;
            margin-right: 5px;
            position: absolute;
            top:3px;
            color: #673622;
            font-size: 20px;
            right: 15px;
        }
    }
    .dropdown{
        display: flex;
        flex-direction: row;
        .nav-item:first-child{
            margin-right: 51px;
        }
        .nav-item:nth-child(2){
            margin-right: 53px;
        }
        .button{
            border: 2px solid #D9E1E7;
            border-radius: 12px;
            padding: 9px 24px;
            i{
                color:#673622;
            }
            cursor: pointer;
            h6{
                display: inline;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: 0.84vw;
                line-height: 17px;
                /* identical to box height */
                margin-left: 5px;

                color: #06152B;
            }
           
        }
    }
    .card-body{
        padding: 60px 30px;
        @media screen and(max-width:1660px) {
            padding: 30px 30px;            
        }
        &__content{
            table{
                thead{
                    background: white;
                    position: sticky;
                    top: 0px;
                }
                th{
                    color: #906B5C;
                    padding: 30px 0px;
                    font-size: 16px;
                    line-height: 19px;
                    &:first-child{
                        width: 44px;
                    }
                    &:nth-child(2){
                        width: 120px;
                    }
                    &:nth-child(3){
                        width: 133px;
                    }
                    &:nth-child(4){
                        width: 190px;
                    }
                    &:nth-child(5){
                        width: 115px;
                    }
                    &:nth-child(6){
                        width: 94px;
                    }
                }
                td{
                    padding:12px 0px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    h6.delete{
                        padding: 13px 16px;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        color: #000;
                    }
                    h6.duty1{
                        color: #00CD6B;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    }
                    h6.duty0{
                        color: #FF3131;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    }
                    &:nth-child(2){
                        font-weight: 700;
                        font-size: 0.9633vw;
                    }
                    &:nth-child(3), &:nth-child(4){
                        font-weight: 500;
                    }

                }

            }
        }
    }
}
.driver .content-panel__content .card-body__header .caption h5 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    margin-bottom: 30px;
    color: #06152B;
}
.image-cropper-modal {
    .modal-content {
        max-width: 500px !important;
        margin:auto;
    }

.image-cropper {
    height: 500px;
    .image_cropper {
        display: inline-block;
    }
    .container {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .reactEasyCrop_Container {
        margin: auto;
        margin-top:0px;
      }
      .cropper {
        position: relative;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border-radius: 8px;
        overflow: hidden;
      }
    
      input[type='radio']{
        accent-color: #F4730E;
      }
      .radio {
        margin: 0.5rem;
        display: inline-block;
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          + .radio-label {
            &:before {
              content: '';
              background: #f4f4f4;
              border-radius: 100%;
              border: 1px solid darken(#f4f4f4, 25%);
              display: inline-block;
              width: 1.4em;
              height: 1.4em;
              position: relative;
              margin-right: 5px;
              margin-left: 5px;
              vertical-align: top;
              cursor: pointer;
              text-align: center;
              transition: all 250ms ease;
            }
          }
          &:checked {
            + .radio-label {
              &:before {
                background-color: #F4730E;
                box-shadow: inset 0 0 0 4px #f4f4f4;
              }
            }
          }
          &:focus {
            + .radio-label {
              &:before {
                outline: none;
                border-color: #F4730E;
              }
            }
          }
          &:disabled {
            + .radio-label {
              &:before {
                box-shadow: inset 0 0 0 4px #f4f4f4;
                border-color: darken(#f4f4f4, 25%);
                background: darken(#f4f4f4, 25%);
              }
            }
          }
          + .radio-label {
            &:empty {
              &:before {
                margin-right: 0;
              }
            }
          }
        }
      }
      
      .btn {
        font-size: 16px;
        color: #ffffff;
        padding: 0.5rem 1.5rem;
        border-radius: 0.2rem;
        border-radius: 12px;
        background: #F4730E;
        cursor: pointer;
      }
      
      .action-btns {
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-around;
      }
    
      .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .cropped-img {
        width: 300px;
        height: 300px;
        object-fit: contain;
      }
}
}

.main {
    font-family: 'Lato';
    font-style: normal;
    .step-count {
        display :none;
        @media screen and (max-width: 767px) {
            display: block;
            padding: 0px 20px 0px 20px;
            font-size: 14px;
            letter-spacing: 2px;
            font-weight: 500;
        }  
    }
    .mobile-pickup-dropoff {
        display: none;
        @media screen and (max-width: 767px) {
            display: block;
            padding: 0px 20px 0px 20px;
            font-weight: 600;
            letter-spacing: 2px;
            margin-top: 26px;
            font-size: 20px;
        } 
    }
    .container {
        border-left: 2px solid #ece3e0;
        border-right: 2px solid #ece3e0;

        padding: 20px 0px 30px 0px;
        @media screen and (max-width: 991px) {
            max-width: 900px;
        }
        @media screen and (max-width: 1400px) and (min-width: 991px) {
            min-width: 1300px;
            padding: 20px 0px 220px 0px;
            overflow-x: scroll;
        }

        @media screen and (min-width: 1660px) {
            max-width: 1540px;
            padding: 20px 0px 220px 0px;
        }
    }
}

#google-map-wrapper {
    @media screen and (max-width: 1400px) and (min-width: 991px) {
        padding-right: 30px;
    } 
    @media screen and (max-width: 991px) {
        width: 100%;
    }  
    @media screen and (max-width: 767px) {
        padding: 0px 20px 0px 20px;
    }  
}
#ride-booking-wrapper {
    @media screen and (max-width: 991px) {
        width: 100%;
    }   
}



.home {
    &-row {
        display: flex;
        justify-content: space-between;
    }

    .sc-bczRLJ {
        padding-left: 10px;
    }

    .ride-booking-wrapper {
        position: relative;
        z-index: 999;

        .ride-booking-details {
            background: #FFFFFF;
            border-radius: 10px;
            padding: 30px;
            padding-top: 50px;

            @media screen and (max-width: 767px) {
                padding: 20px !important;
            }
            .booking-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 56px;
                padding-left: 40px;

                @media screen and (max-width: 767px) {
                    display: block;
                    padding:0px;
                    margin-bottom: 0px;
                }
                
                .ride-now {
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #673622;

                    .react-toggle {
                        margin-left: 20px;

                        .react-toggle-track {
                            width: 51px;
                            height: 31px;
                            background-color: rgba(0, 0, 0, 0.07);
                        }

                        .react-toggle-thumb {
                            top: 2px;
                            width: 27px;
                            height: 27px;
                            background-color: #b8b8b8;
                            border-color: #b8b8b8;
                            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
                        }

                        &.react-toggle--checked {
                            .react-toggle-thumb {
                                background-color: #F4730E;
                                border-color: #F4730E;
                            }
                        }
                    }
                }

                .control-btns {
                    .nav {
                        @media screen and (max-width: 767px) {
                            display: table;
                            width: 100%;
                            margin-top: 20px;
                        }
                    }
                    .nav h5:first-child {
                        @media screen and (max-width: 767px) {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }  
                    }
                    .nav h5:last-child {
                        @media screen and (max-width: 767px) {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }  
                    }
                    .nav-link {
                        @media screen and (max-width: 767px) {
                            display: table-cell;
                        }
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #000000;
                        margin: 0;
                        cursor: pointer;
                        padding: 10px 26px;
                        text-transform: uppercase;
                        border: 1px solid #000000;
                        border-radius: 10px;
                        margin-left: 5px;
                        img {
                            margin-right: 10px;
                        }

                        &.active {
                            background: #673622;
                            color: #fff;
                            border-color: #673622;
                        }
                    }
                }
            }
            .ride-booking-date-time-wrapper {
                padding-top: 0;
                padding-left: 40px;
                padding-right: 0;
                padding-bottom: 0;
                margin-bottom: 33px;

                .row > div:first-child {
                    @media screen and (max-width: 767px) {
                        padding-bottom: 20px !important;
                        padding-top: 20px;
                    } 
                } 
                .ride-booking-input {
                    padding-right: 30px;
                    border: none !important;
                }
                @media screen and (max-width: 767px) {
                    margin-bottom: 0px;
                }
            }
            .ride-booking-data-wrapper {
                padding-left: 40px;
                @media screen and (max-width: 767px) {
                    padding: 0px !important;
                }
                .ride-booking-data {
                    margin-bottom: 25px;

                    .ride-booking-data-header {
                        background-color: #673622;
                        display: flex;
                        align-items: center;
                        padding: 15px 27px;
                        border-radius: 5px 5px 0px 0px;

                        @media screen and (max-width: 767px) {
                            display: block;
                        }  

                        .booking-type {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #fff;
                            opacity: 0.6;
                            margin-right: 35px;
                            position: relative;
                            cursor: pointer;
                            @media screen and (max-width: 767px) {
                                margin-right: 18px;
                                width: 100px;
                                display: inline;
                            }  

                            &:before {
                                content: '';
                                position: absolute;
                                left: -18px;
                                top: 6px;
                                height: 7px;
                                width: 1px;
                                background-color: #fff;
                                @media screen and (max-width: 767px) {
                                    left: -7px;
                                }  

                            }

                            &.active {
                                font-weight: 700;
                                opacity: 1;
                            }

                            &:first-child {
                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }

                    .ride-booking-data-inputs {
                        background: rgba(103, 54, 34, 0.1);
                        border-radius: 0px 0px 5px 5px;
                        padding: 14px 20px;
                        border: 1px solid #673622;
                        .ride-booking-data-input-wrapper {
                            display: flex;
                            align-items: center;
                            width: 100%;

                            .ride-booking-data-title {
                                width: 90px;
                                .title {
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #000000;
                                }
                            }


                            &.pickup {
                                position: relative;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    width: 17px;
                                    height: 17px;
                                    background-image: url('../../../images/location.svg');
                                    left: -50px;
                                    top: 10px;

                                    @media screen and (max-width: 767px) {
                                        display: none;
                                    }
                                }

                                &:after {
                                    content: "";
                                    position: absolute;
                                    border: 1px solid #e7e1e1;
                                    left: -52px;
                                    top: 3px;
                                    width: 21px;
                                    height: 40px;
                                    border-top-left-radius: 30px;
                                    border-bottom: none;
                                    border-top-right-radius: 30px;

                                    @media screen and (max-width: 767px) {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .stop {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            position: relative;

                            &:before {
                                content: '';
                                position: absolute;
                                width: 26px;
                                height: 26px;
                                background-image: url('../../../images/stop.svg');
                                left: -55px;
                                top: 10px;
                                @media screen and (max-width: 767px) {
                                    display: none;
                                }
                            }

                            .stop-location {
                                display: flex;
                                align-items: center;

                                .title {
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #000000;
                                    width: 96px;
                                }

                                .auto-complete-wrapper {
                                    display: flex;
                                    padding: 0;
                                    width: calc(100% - 96px);

                                    .icon-type {
                                        margin-right: 18px;
                                    }

                                    .MuiAutocomplete-root {
                                        width: 100%;
                                    }
                                }

                                .css-1qqsdnr-MuiAutocomplete-root {
                                    width: 100%;

                                    .MuiInput-root .MuiInput-input {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 21px;
                                        color: rgba(0, 0, 0, 0.6);
                                    }
                                }
                            }
                        }

                        .dropoff {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            position: relative;

                            &:before {
                                content: '';
                                position: absolute;
                                width: 17px;
                                height: 17px;
                                background-image: url('../../../images/location.svg');
                                left: -50px;
                                top: 10px;
                                @media screen and (max-width: 767px) {
                                    display: none;
                                }
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                border: 1px solid #e7e1e1;
                                left: -52px;
                                bottom: -3px;
                                width: 21px;
                                height: 40px;
                                border-bottom-left-radius: 30px;
                                border-top: none;
                                border-bottom-right-radius: 30px;
                                @media screen and (max-width: 767px) {
                                    display: none;
                                }
                            }

                            .title {
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 19px;
                                color: #000000;
                                width: 90px;
                            }

                            .auto-complete-wrapper {
                                display: flex;
                                padding: 0;

                                .icon-type {
                                    margin-right: 18px;
                                }

                                .MuiAutocomplete-root {
                                    width: 100%;
                                }
                            }

                            .css-1qqsdnr-MuiAutocomplete-root {
                                width: 100%;

                                .MuiInput-root .MuiInput-input {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: rgba(0, 0, 0, 0.6);
                                }
                            }
                        }
                        .check, .trash {
                            cursor: pointer;
                        }
                    }

                    .ride-booking-data-input {
                        width: calc(100% - 90px);
                        display: flex;

                        .auto-complete-wrapper {
                            display: flex;
                            padding: 0;

                            .icon-type {
                                margin-right: 18px;
                            }
                        }

                        .MuiAutocomplete-root {
                            width: 100%;

                            .MuiInput-root .MuiInput-input {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 21px;
                                color: rgba(0, 0, 0, 0.6);
                            }
                        }

                        &.airport {
                            .d-flex {
                                width: 100%;
                                margin-top: 24px;

                                .ride-booking-data-inputs {
                                    width: 32%;
                                    margin-right: 1%;

                                    &:last-child {
                                        margin-right: 0;
                                    }

                                    input {
                                        background: transparent;
                                        border: 0;

                                        &:focus {
                                            outline: 0;
                                            border: 0;
                                        }
                                    }
                                }
                            }

                        }
                        .check {
                            cursor: pointer;
                        }
                    }

                    &.timing {
                        display: flex;
                        align-items: center;
                        margin-top: 20px;
                        .hour-min-input {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            @media screen and (max-width: 767px) {
                                display: block;
                            }    
                            .hour-min:first-child {
                                @media screen and (max-width: 767px) {
                                margin-bottom: 20px;
                                }   
                            }
                            .hour-min:last-child {
                                @media screen and (max-width: 767px) {
                                margin-bottom: -20px;
                                }
                            }
                            .hour-min {
                                background: rgba(103, 54, 34, 0.1);
                                padding:0px 0px 0px 14px;
                                border-radius: 5px;
                                margin-right: 12px;
                                width: 100%;
                                border: 1px solid #673622;
                                height: 60px;
                                @media screen and (max-width: 767px) {
                                    width: 100%;
                                }
                                .hour-input {
                                    display: flex;
                                    width: 100%;

                                    justify-content: space-between;

                                    .hour-input__wrapper {
                                        display: flex;
                                        align-items: center;

                                        .title {
                                            white-space: nowrap;
                                            font-weight: 600;
                                            font-size: 12px;
                                            line-height: 14px;
                                            color: #000000;
                                            margin-right: 10px;
                                        }

                                        input {
                                            background: transparent;
                                            border: 0;
                                            color: rgba(0, 0, 0, 0.6);
                                            font-weight: 400;
                                            font-size: 16px;
                                            line-height: 19px;
                                            width: 38px;

                                            &:focus-visible {
                                                outline: 0;
                                            }
                                        }
                                    }

                                    .plus-minus {
                                        display: flex;
                                        flex-direction: column;
                                        .minus {
                                            width: 47px;
                                            height: 28.6px;
                                            border: 1px solid #673622;
                                            color: #673622;
                                            background-color: #fff;
                                            border-radius: 0px 0px 5px 0px;
                                            border-right: 0;
                                            border-bottom: 0;
                                        }

                                        .plus {
                                            width: 47px;
                                            height: 30px;
                                            background-color: #673622;
                                            border: 1px solid #673622;
                                            color: #fff;
                                            border-top: 0;
                                            border-top-right-radius: 4px;
                                            border-right: 0;
                                        }
                                    }
                                }
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }

                        .addstop {
                            display: flex;
                            width: 15%;
                            justify-content: center;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            color: #673622;
                            text-decoration-line: underline;
                            cursor: pointer;

                            i {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }

            .addstop {
                text-align: right;
                .add-stop {
                    display: inline-block;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    text-decoration: underline;
                    color: #673622;
                    i {
                        margin-right: 12px;
                    }
                }
            }
        }
        .ride-booking-sidebar.counts {
            @media screen and (max-width: 767px) {
                padding:0px 20px 0px 20px !important;  
            } 
        }
        .ride-booking-sidebar {
            background: #FFFFFF;
            border-radius: 10px;
            padding: 30px;
            padding-top: 0px;
            padding-left: 70px;
            @media screen and (max-width: 767px) {
                padding:0 0px 20px 0px !important;  
            } 

            .ride-booking-input {
                background: rgba(103, 54, 34, 0.1);
                border-radius: 5px;
                display: flex;
                margin-bottom: 20px;
                align-items: center;
                padding: 20px 0px 20px 22px;
                height: 60px;
                border: 1px solid #673622;
                .title {
                    margin-right: 10px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    color: #000;
                    white-space: nowrap;
                }

                // &:last-child {
                //     margin-bottom: 0px;
                // }
                .inputs {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 82%;
                    position: relative;

                    @media screen and (max-width: 767px) {
                        width: 100% !important;
                    }

                    img {
                        position: absolute;
                        right: 0;
                        top: 12px !important;
                    }

                    .picker {
                        .picker-input { 
                            width: 105%;
                            cursor: pointer;
                            z-index: 100;
                        }
                        .picker-input__text {
                            background-color: transparent;
                            border: 0;
                            color: rgba(0, 0, 0, 0.6);
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }

                    .count {
                        color: rgba(0, 0, 0, 0.6);
                    }

                    .btns {
                        display: flex;
                        flex-direction: column;
                        .minus {
                            width: 47px;
                            height: 28.6px;;
                            background-color: transparent;
                            border: 1px solid #673622;
                            color: #673622;
                            background-color: #fff;
                            border-radius: 0px 0px 5px 0px;
                            border-right: 0;
                            border-bottom: 0;
                        }

                        .plus {
                            width: 47px;
                            height: 30px;
                            background-color: #673622;
                            border: 1px solid #673622;
                            color: #fff;
                            border-top: 0;
                            border-top-right-radius: 4px;
                            border-right: 0;
                        }
                    }
                }

                .picker__container {
                    left: inherit !important;
                    right: 0;

                    .time__container {
                        border: 1px solid rgba(103, 54, 34, 0.5);
                        border-radius: 10px;

                        .time-input__up,
                        .time-input__down,
                        .time-input__text {
                            border: 0;

                            button {
                                background: transparent;

                                svg {
                                    border: 1px solid rgba(41, 45, 50, 1);
                                    border-radius: 4px;
                                }
                            }
                        }

                        .time-input__text input {
                            font-size: 24px;
                            color: #673622;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        .select-vehicle {
            width: 100%;
            margin-top: 45px;
            background: #F4730E;
            border-radius: 12px;
            font-weight: 700;
            font-size: 22px;
            line-height: 65px;
            color: #fff;
            text-align: center;
            height: 65px;
            cursor: pointer;
        }
        .action-btns {
            padding: 20px;
            padding-left: 70px;
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 767px) {
                padding-left: 20px;
            }

            .btn-outline {
                border: 1px solid #F4730E;
                background-color: #fff;
                width: 185px;
                height: 50px;
                border-radius: 12px;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #F4730E;
                text-align: center;
                @media screen and (max-width: 767px) {
                    width: auto;
                }  
                &:hover {
                    background-color: #F4730E;
                    color: #fff;
                }
            }
            .btn-fill {
                border: 1px solid #F4730E;
                background-color: #F4730E;
                width: 185px;
                height: 50px;
                border-radius: 12px;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #fff;
                text-align: center;

                @media screen and (max-width: 767px) {
                    width: auto;
                }  
                &:hover {
                    background-color: #fff;
                    color: #F4730E;
                }
            }
        }
    }

    .google-map {
        height: 700px;
        margin-top: 40px;
        .map-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #fff;
            background-color: #673622;
            text-transform: uppercase;
            text-align: center;
            padding: 10px;
            border-radius: 3px 3px 0px 0px;

            @media screen and (max-width: 767px) {
            font-size: 15px;
            }  
        }
    }
}



// Design of Model Dialog
.modal-dialog {
    max-width: 1290px !important;

    @media screen and (max-width: 1440px) {
        max-width: 1024px !important;
    }

    @media screen and (max-width: 1200px) {
        max-width: 800px !important;
    }
}

.modal-body {
    padding: 0 !important;
    width: 100%;

    .btns {
        margin-top: 80px;
        display: table;
        width: 100%;

        @media screen and (max-width: 767px) {
            position: absolute;
            bottom: 10px;
            left: 8px;
        }

        .confirm {
            width: 300px;
            height: 50px;
            background: #f4730e;
            border-radius: 12px;
            border: none;
            color: white;
            font-size: 0.93vw;
        }

        h6.update {
            padding: 14px 81px;
            width: unset;
            display: table-cell;
            color: #FFF;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @media screen and (max-width: 767px) {
                padding: 10px 20px;
                font-size: 12px;
            }
            @media screen and (max-width: 1440px) {
                padding: 10px 20px;
                font-size: 14px;
            }
        }

        .edit {
            border: none;
            margin-right: 24px;
            background-color: unset;
            display: table-cell;
            width: 100%; 
            color: #F4730E;
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
            @media screen and (max-width: 991px) {
                font-size: 12px;
            }            
            @media screen and (max-width: 1440px) {
                font-size: 14px;
            }
        }
    }

    .header-wrap {
        padding: 110px 80px;
        border-right: 1px solid #ece3e0;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 1440px) {
            padding: 50px 30px;
        }

        .title {
            color: #000;
            font-family: Lato;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .description {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #000000;
            margin-top: 24px;

            h6 {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: rgb(0, 0, 0);
                span {
                    color: rgba(0, 0, 0, 0.6);
                }
            }
        }

        .img-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 60px;
            position: relative;
        }

        .i-wrapper {
            position: absolute;
            bottom: -10px;
            padding: 6px 6px;
            background-color: #f4732a;
            border-radius: 10px;
            /* vertical-align: middle; */
            display: flex;
            align-items: center;
        }
    }

    .content-wrap {
        padding: 60px 40px;
        width: 100%;

        @media screen and (max-width: 1440px) {
            padding: 50px 10px;
        }
        @media screen and (max-width: 767px) {
            padding: 0px 10px 60px 10px;
        }

        .input-wrapper {
            margin-left: 0px;
            position: relative;

            input {
                width: 100%;
                margin-left: 10px;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                color: #000000;

                opacity: 0.6;
            }
        }

        .street,
        .city,
        .state,
        .postalcode,
        .country {
            width: 100%;
            height: 60px;
            border-radius: 5px;
            padding: 20px 26px;
            margin-bottom: 14px;
            opacity: 1;
            background: #67362212;;

            .label {
                color: #000;
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                width: 88px;
            }

            input {
                border: none !important;
                background: none;
                outline: 0;
                box-shadow: none;
            }
        }
    }
}

.booking-type.type {
    color: #f1f1f1 !important;
    opacity: 1 !important;
}

.arivalTimeInput {
    display: flex;
    position: relative;
}

img.timepickerArival {
    float: right;
    margin-top: 4px;
    cursor: pointer;
    position: absolute;
    right: 20px;
}

.arivalTimeInput input.timeType {
    z-index: 99999;
    cursor: pointer;
}

ul.flag-ul {
    display: block;
    position: absolute;
    top: 40px;
    z-index: 9;
    padding-left: 1px;
}

ul.flag-ul li {
    /* position: absolute; */
    /* height: 1000px; */
    position: relative;
    list-style-type: none;
    cursor: pointer;
    padding: 2px 18px;
    background-color: #ffffff;
    border-radius: 2px
}

button.flag-btn {
    padding: 0px 5px;
    border-radius: 3px;
    padding-bottom: 3px;
    background-color: #fff;
    border: 1px solid #f1f1f1 !important;
}

button.flag-btn i.fas.fa-chevron-down {
    padding: 6px 5px;
}

// input.timeType:before {
//     content:'Arrival time:' ;
//     margin-right:.6em;
//     color:#9d9d9d;
//   }

input.timeType:not(:valid):before {
    content:'Arrival time:' !important;
    margin-right:.6em;
    color:#9d9d9d;
  }

  .ride-booking-data-input.airport {
    width: 100% !important;
}

.datetimeInput {
    margin-bottom: 0 !important;
}
.price-qoute {
    color: #673622;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.react-date-picker {
    width: 100%;
    img#calender-icon {
        top: 3px !important;
    }
    .react-date-picker__wrapper {
        border: none;
    }
}
.react-date-picker__inputGroup {
    text-align: center;
}
.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month {
    appearance: none;
    background-image:url('');
}